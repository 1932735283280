@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}

.ql-toolbar{
  border:none !important;
}

.ql-container{
  border:none !important;
}

* {
  user-select: none;
  -webkit-user-select: none; /* For Safari */
  -ms-user-select: none; /* For IE/Edge */
}

.selectable {
  user-select: text !important;
  -webkit-user-select: text !important; /* For Safari */
  -ms-user-select: text !important; /* For IE/Edge */
}